<template>
  <div class="body">
    <div class="device-join">
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom: 10px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="24">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="电站名称">
                <el-select v-model="formInline.powerStationCode" placeholder="请选择电站" clearable>
                  <el-option v-for="item in powerStationList" :key="item.id" :label="item.powerStationName"
                    :value="item.powerStationCode">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="状态">
                <el-select v-model="formInline.status" placeholder="请选择" clearable>
                  <el-option label="激活" :value="1"></el-option>
                  <el-option label="消除" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="级别">
                <el-select v-model="formInline.level" placeholder="请选择" clearable>
                  <el-option label="正常状态" value="0"></el-option>
                  <el-option label="紧急状态" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="告警类型">
                <el-select v-model="formInline.signalTypeName" placeholder="请选择" clearable>
                  <el-option
                    v-for="item in alarmTypeOptions"
                    :key="item.id"
                    :label="item.alarmTypeName"
                    :value="item.alarmTypeName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="产生时间">
                <el-date-picker
                  type="date"
                  v-model="formInline.createtime"
                  placeholder="请选择"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>

      <!-- 表格部分 -->
      <div>
        <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%"
          height="calc(100vh - 240px)" max-height="75vh">
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column label="厂站名称" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.powerStationName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备名称" align="center">
            <template slot-scope="scope">
              <span style="color: #80ffff">{{ scope.row.deviceName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="告警名称" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="告警类型" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.signalTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="级别" align="center">
            <template slot-scope="scope">
              <span style="color: #f59a23">{{
                scope.row.level == 1 ? "紧急" : "正常状态"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="80px">
            <template slot-scope="scope">
              <span style="color:#75bb65" v-if="scope.row.status == 1">激活</span>
              <span style="color:red" v-if="scope.row.status == 0">消缺</span>
            </template>
          </el-table-column>
          <el-table-column label="修复建议" show-overflow-tooltip align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.dealMessage }}</span>
            </template>
          </el-table-column>

          <el-table-column label="产生时间" width="95px" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.createtime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="恢复时间" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 1 ? "" : scope.row.updatetime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100px">
            <template slot-scope="scope">
              <el-button type="text" @click="createWorkOrderApply(scope.row)"
                >转工单</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage" :page-sizes="[5, 10, 20, 50]" :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import exportBtn from '@/components/exportBtn.vue'
import { formatDate } from "../../../utils/formatDate";
export default {
  data() {
    return {
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {},
      },
      form: {},
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0,
      formInline: {
        // dateFormat: '%Y-%m-%d',
      },
      powerStationList: [], //电站
      getpowerStationName: {},
      alarmTypeOptions: [], //告警类型
    };
  },
  components:{
    exportBtn
  },

  created() {
    this.getList();
    this.getSelectList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 240;
    },

    //获取数据
    async getList() {
      if (this.formInline.createtime) {
        this.formInline.createtime = formatDate(
          new Date(this.formInline.createtime),
          "yyyy-MM-dd"
        );
      }
      this.queryInfo.condition = this.formInline;
      this.queryInfo.condition.powerStationName = this.getpowerStationName[this.formInline.powerStationCode] || ''
      this.$http.post("/deviceAlarmRecord/list", this.queryInfo).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        }
      });
    },

    // 查询
    search() {
      this.getList();
    },
    getSelectList() {
      // 电站列表
      this.$http
        .post("powerStation/list", { currPage: 1, pageSize: 100 })
        .then((res) => {
          if (res.data.code == 200) {
            this.powerStationList = res.data.data.data;
            this.powerStationList.forEach(item => {
              this.getpowerStationName[item.powerStationCode] = item.powerStationName
            })
          } else {
            this.$message.error(res.data.message);
          }
        });

      this.$http.post("alarmType/list", {}).then((res) => {
        if (res.data.code === 200) {
          this.alarmTypeOptions = res.data.data.data;
        }
      });
    },
    // 转工单
    createWorkOrderApply(item) {
      localStorage.setItem("powerStationCode", item.powerStationCode)
      this.$router.push(
        `/yunwei/yunwei/goWorkOrder?createby=${item.createby}&id=${item.id}`
      );
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.body {
  background: #223f6c;
  padding: 10px;
  height: 100%;

  .device-join {
    height: 100%;
    background: #2d5981;
    padding: 8px;

    #default-btn::file-selector-button {
      padding: 5.5px 10px;
      background-color: #409eff;
      border: 1px solid #409eff;
      border-radius: 3px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
    }

    .demo-form-inline{
      /deep/.el-form-item{
        margin-bottom: 0;
      }
    }
    .form-class {
      /deep/ .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
        font-size: 16px;
        padding-top: 5px;
        box-sizing: border-box;
      }

      /deep/ .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
      /* 表单 */
      /deep/.el-select{
        width: -webkit-fill-available;
      }
    }

    /deep/ .el-input-group__append {
      background: #409eff;
      color: #fff;
    }

    /deep/ .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }

    /deep/ .el-input__inner {
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
    }

    /deep/ .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }

    /deep/ .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    /deep/ .el-table__body-wrapper {
      background: #2d5981;
    }

    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: #223f6c !important;
    }

    /deep/ .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    /deep/ .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }

    /deep/ .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }

    /deep/ .el-pager li {
      background: none;
      color: #f2f2f2;
    }

    /deep/ .el-icon {
      color: #fff;
    }

    /deep/ .el-pagination .btn-prev {
      background: none;
    }

    /deep/ .el-pagination .btn-next {
      background: none;
    }

    /deep/ .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }

    /deep/ .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }
  }
}

/deep/ .el-icon-close:before {
  color: #333;
}

#default-btn::file-selector-button {
  padding: 5.5px 10px;
  background-color: #409eff;
  border: 1px solid #409eff;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

#default-btn {
  font-size: 0;
  margin: 0;
  padding: 0;
  // margin-left: 10px;
  // margin-right: 6px;
  // margin: 1px 6px 0 10px;
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #f59a23 !important;
}

::v-deep .el-form-item__label {
  text-align: end !important;
}

::v-deep .el-dialog__body {
  padding: 0 30px !important;
  text-align: center;
}

::v-deep .el-form-item--small.el-form-item {
  height: 36px !important;
}
</style>
